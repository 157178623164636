<template>
  <div>
    <ayl-berad-nav :nav='customer' v-if="this.nav ==='customer'"></ayl-berad-nav>
    <ayl-berad-nav :nav='driver' v-if="this.nav ==='driver'"></ayl-berad-nav>
    <ayl-berad-nav :nav='car' v-if="this.nav ==='car'"></ayl-berad-nav>
    <ayl-berad-nav :nav='business_type' v-if="this.nav ==='business_type'"></ayl-berad-nav>
    <ayl-berad-nav :nav='site' v-if="this.nav ==='site'"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px" style="padding: 0 0 24px 0">
        <div style="border-bottom: 1px solid #E8E8E8;padding: 20px" class="mb40px">
          订单流转
        </div>
        <el-steps align-center process-status="wait" :active="active">
          <el-step v-for="(item, idx) in stepList" :key="idx">
            <div slot="icon">
            </div>
            <div slot="title">
              {{item}}
            </div>
            <div slot="description">
             <span style="display: block">
               {{form.operationLogInfoVOList[idx]&&form.operationLogInfoVOList[idx].nickName||'- -'}}
               {{form.operationLogInfoVOList[idx]&&form.operationLogInfoVOList[idx].phone}}
             </span>
              <span style="display: block">
               {{form.operationLogInfoVOList[idx]&&form.operationLogInfoVOList[idx].gmtCreate | str2ymdhm}}
             </span>
            </div>
          </el-step>
        </el-steps>
      </div>
      <div class="content-main" v-if="form.orderStatus !== '已废单'">
        <div class="form-label-base">基本信息</div>
        <div class="details-form-item">
          <span>订单编号：</span>
          <span>{{this.form.orderId||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>业务类型：</span>
          <span>{{this.form.orderType||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>客户名称：</span>
          <span>{{this.form.partnerName||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>结算客户：</span>
          <span>{{this.form.partnerAccountName||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">运输信息</div>
        <div class="details-form-item">
          <span>运输日期：</span>
          <span>{{this.form.transportTime|str2ymd}}</span>
        </div>
        <div class="details-form-item">
          <span>驾驶员：</span>
          <span>{{this.form.driverName||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>提货车号：</span>
          <span>{{this.form.plateNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>到货车号：</span>
          <span>{{this.form.cargoArrivalNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>提货单号：</span>
          <span>{{this.form.cargoDeliveryNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>中转单号：</span>
          <span>{{this.form.cargoTransferNumber||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>起运地：</span>
          <span>{{this.form.sendAddressDetail||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>发货人：</span>
          <span>{{this.form.senderName||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>发货人手机号码：</span>
          <span>{{this.form.senderContactPhone||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>目的地：</span>
          <span>{{this.form.receiveAddressDetail||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收货人：</span>
          <span>{{this.form.receiverName||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收货人手机号码：</span>
          <span>{{this.form.receiverContactPhone||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">货物信息</div>

        <div class="details-form-item">
          <span>品名：</span>
          <span>{{this.form.category||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>吨数：</span>
          <span>{{this.form.categoryWeight||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>体积(立方米)：</span>
          <span>{{this.form.volume||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>件数：</span>
          <span>{{this.form.categoryPiece||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>货位：</span>
          <span>{{this.form.cargoLocation||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>去向：</span>
          <span>{{this.form.cargoDirection||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>保管员：</span>
          <span>{{this.form.keeper||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='内转'">
          <span>货运员：</span>
          <span>{{this.form.freightAgent||'- -'}}</span>
        </div>

        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>库位：</span>
          <span>{{this.form.warehouseLocation||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>资源号：</span>
          <span>{{this.form.resourceId||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>规格：</span>
          <span>{{this.form.specification||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>型号：</span>
          <span>{{this.form.steelModel||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>钢种：</span>
          <span>{{this.form.steelType||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>产地：</span>
          <span>{{this.form.steelProduction||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>开单员：</span>
          <span>{{this.form.partOrder||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>收货员：</span>
          <span>{{this.form.receivingClerk||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>理货员：</span>
          <span>{{this.form.tallyClerk||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>审核员：</span>
          <span>{{this.form.checker||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>装卸班组：</span>
          <span>{{this.form.loadUploadCrew||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">费用信息</div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>运费(元)：</span>
          <span>{{this.form.earningFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='直拨'">
          <span>拼车费(元)：</span>
          <span>{{this.form.carpoolingFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='内转'">
          <span>吊装费(元)：</span>
          <span>{{this.form.liftingFee||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>提成系数(%)：</span>
          <span>{{this.form.driverBonusFee||'- -'}}</span>
        </div>

        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收入单价(元)：</span>
          <span>{{this.form.earningUnitPrice||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>收入金额(元)：</span>
          <span>{{this.form.earningFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>过磅费(元)：</span>
          <span>{{this.form.weightFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>卸车费(元)：</span>
          <span>{{this.form.unloadFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>成本单价(元)：</span>
          <span>{{this.form.costUnitPrice||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>成本运费(元)：</span>
          <span>{{this.form.costCarrierFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>过路过桥费(元)：</span>
          <span>{{this.form.tollsFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>加油费(元)：</span>
          <span>{{this.form.fuelFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>罚款(元)：</span>
          <span>{{this.form.fine||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>其他费用(元)：</span>
          <span>{{this.form.otherFee||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType==='外发'">
          <span>信息费(元)：</span>
          <span>{{this.form.messageFee||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">其他信息</div>

        <div style="margin-bottom: 20px">
          <span>下单备注：</span>
          <span>{{this.form.cargoShipmentRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>订单备注：</span>
          <span>{{this.form.orderRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>交单备注：</span>
          <span>{{this.form.deliveryRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>核算备注：</span>
          <span>{{this.form.checkRemark||'- -'}}</span>
        </div>

        <div class="details-line"></div>
        <div class="form-label-base">图片信息</div>
        <!--图片-->
        <div class="order-img" :style="{'margin-right': (idx+1)%4===0?'0px':''}"
             v-for="(item, idx) in bigImgList" :key="idx">
          <el-image
                    :preview-src-list="imgSrcList"
                    :src="bigImages[idx]"
                    fit="cover"
                    style="width: 100%;height: 100%;border-radius: 4px"
                    @click="onPreview(idx)"
                    lazy
          ></el-image>
          <div class="img-text">
            <div>
              <span>{{item.userName||'- -'}}</span>
              &nbsp;
              <span>{{item.uploaderPhone||'- -'}}</span>
            </div>
            <span>{{item.gmtCreate|str2ymdhm}}</span>
          </div>
          <div class="img-icon">
            {{item.imageType}}
          </div>
          <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i>
        </div>
      </div>
      <div class="content-main" v-else>
        <div class="form-label-base">交单信息</div>
        <div class="details-form-item">
          <span>业务类型：</span>
          <span>{{this.form.orderType||'- -'}}</span>
        </div>
        <div class="details-form-item" v-if="form.orderType!=='外发'">
          <span>交单人：</span>
          <span>{{this.form.orderName||'- -'}}</span>
        </div>
        <div class="details-form-item" v-else>
          <span>下单人：</span>
          <span>{{this.form.orderName||'- -'}}</span>
        </div>
        <div class="details-form-item">
          <span>手机号码：</span>
          <span>{{this.form.phone||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>下单备注：</span>
          <span>{{this.form.cargoShipmentRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>订单备注：</span>
          <span>{{this.form.orderRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>交单备注：</span>
          <span>{{this.form.deliveryRemark||'- -'}}</span>
        </div>
        <div style="margin-bottom: 20px">
          <span>核算备注：</span>
          <span>{{this.form.checkRemark||'- -'}}</span>
        </div>
        <div class="details-line"></div>
        <div class="form-label-base">图片信息</div>
        <!--图片-->
        <div class="order-img" :style="{'margin-right': (idx+1)%4===0?'0px':''}"
             v-for="(item, idx) in bigImgList" :key="idx">
          <el-image
                    :preview-src-list="imgSrcList"
                    :src="bigImages[idx]"
                    fit="cover"
                    style="width: 100%;height: 100%;border-radius: 4px"
                    @click="onPreview(idx)"
                    lazy
          ></el-image>
          <div class="img-text">
            <div>
              <span>{{item.userName||'- -'}}</span>
              &nbsp;
              <span>{{item.uploaderPhone||'- -'}}</span>
            </div>
            <span>{{item.gmtCreate|str2ymdhm}}</span>
          </div>
          <div class="img-icon">
            {{item.imageType}}
          </div>
          <i class="el-icon-error close-icon" v-if="item.isClear" @click="clickClearImg(idx)"></i>
        </div>
      </div>
      <div class="btn-main">
        <el-button @click="toVoid" v-if="$route.query.isToVoid" type="primary">废 单</el-button>
        <el-button v-if="orderStatus" type="primary" @click="clickPrint" :loading="btnLoading">打印订单信息</el-button>
        <el-button @click="$router.go(-1)">返 回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        bigImgList:[],
        bigImages:[],
        imgSrcList:[],
        nav: null,
        customer: [{name: "业务报表", path: ""}, {name: "客户报表", path: "/business-report/customer-statements"}, {name: "订单明细",path: "go(-1)"}, {name: "订单详情", path: ""}],
        driver: [{name: "业务报表", path: ""}, {name: "驾驶员报表", path: "/business-report/driver-report"}, {name: "订单明细", path: "go(-1)"}, {name: "订单详情", path: ""}],
        car: [{name: "业务报表", path: ""}, {name: "车辆报表", path: "/business-report/vehicl-report"}, {name: "订单明细", path: "go(-1)"}, {name: "订单详情", path: ""}],
        business_type: [{name: "业务报表", path: ""}, {name: "业务类型报表", path: "/business-report/business-type-report"}, {name: "订单明细", path: "go(-1)"}, {name: "订单详情", path: ""}],
        site: [{name: "业务报表", path: ""}, {name: "地点报表", path: "/business-report/site-report"}, {name: "订单明细", path: "go(-1)"}, {name: "订单详情", path: ""}],

        form: {},
        stepList: [],
        active: null,
        btnLoading: false,
        orderStatus: false,
      }
    },
    async mounted () {
      this.nav = this.$route.query.type
    },
    methods: {
      // 依次查看大图
      onPreview(index){
        let arr1 = this.bigImages.filter((arrItem, arrIndex) => index <= arrIndex);
        let arr2 = this.bigImages.filter((arrItem, arrIndex) => index > arrIndex);
        let arr3 = arr2.sort((a, b) => {
          b - a;
        });
        this.imgSrcList = [...arr1, ...arr3];
      },

      async clickPrint () {
        this.btnLoading = true
        try {
          const url = await this.$api.printOrder({orderId: this.$route.query.orderId})
          window.open(url)
        } catch (e) {
          console.log(e)
        }
        this.btnLoading = false
      },
      async toVoid() {
        await this.$api.deleteOrder({
          orderIdList: [this.$route.query.orderId]
        })
        this.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success'
        })
        this.$router.go(-1)
      }
    },
    // beforeRouteLeave (to, from, next) {
    //   to.meta.keepAlive = true 
    //   next()
    // },
    async created () {
      this.form = await this.$api.OrderGet({
        orderId: this.$route.query.orderId
      })
      if (this.form.orderStatus === '已废单') {
        // this.stepList = ['待录单', '已废单']
        this.stepList = this.form.operationLogInfoVOList.map(e => e.operationType)
        this.active = this.form.operationLogInfoVOList.length
        return
      }
      this.stepList = this.form.orderType === '外发' ? this.$enums.OrderStatus_w : this.$enums.OrderStatus_n
      this.stepList = this.form.operationLogInfoVOList[0] && this.form.operationLogInfoVOList[0].driverOrOperator ? this.stepList : this.$_.drop(this.stepList, 1)
      this.active = this.form.operationLogInfoVOList.length
      if (this.$route.query.orderStatus == '已审核') {
        this.orderStatus = true
      }
      this.form.imageInfoVOS.forEach(item=>{
        if(item.imageUrl.length>=2){
          for (let i of item.imageUrl) {
            this.bigImages.push(i)
              this.bigImgList.push({
                gmtCreate: item.gmtCreate,
                gmtModified: item.gmtModified,
                imageRemark: item.imageRemark,
                imageType: item.imageType,
                imageUrl: i,
                orderId: item.orderId,
                tenantId: item.tenantId,
                uploaderPhone: item.uploaderPhone,
                userIdentity: item.userIdentity,
                userName: item.userName,
              })
          }
        }else{
          let img = item.imageUrl[0]
          this.bigImages.push(img)
          this.bigImgList.push(item)
        }
      })
    },
  }
</script>

<style lang='sass' scoped>
/deep/ .el-image-viewer__close
   color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
   border: 3px solid #fff
</style>
